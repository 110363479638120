import { useContext } from "react";
import { FacilitiesContext } from "../providers/facility-provider/FacilitiesContext";
import { AuthContext } from "../providers/auth-provider/AuthProvider";
import { AccountContext } from "../providers/account-provider/AccountProvider";

// TODO: locks is at a facility level, whereas ledgers is at a company level.
// ---> unify this somehow...
export const useAppContext = () => {
    const { selectedFacility, fetchingFacilities } = useContext(FacilitiesContext);
    const { phoneNumber } = useContext(AuthContext);
    const { ledgers, fetchingLedgers } = useContext(AccountContext);

    const facilityLedgers = selectedFacility ? ledgers.filter(ledger => ledger.facilityId === selectedFacility?.id) : [];

    const gateLockedAtFacility = ledgers.some(l => l.gateLocked && selectedFacility?.id === l.facilityId)

    return {
        loadingAppContext: fetchingFacilities || fetchingLedgers,
        selectedFacility,
        ledgers,
        phoneNumber,
        facilityLedgers,
        gateLockedAtFacility,
    };
};

// export function createThrottle<T>(cb: (...params: any[]) => Promise<T>, timeout: number = 2000) {
//     let result: (T | null) = null
//     let throttling = false
//     return async function (...params: any[]) {
//         if (!result) {
//             setTimeout(() => {
//                 result = null
//             }, timeout)
//             result = await cb(...params)
//         }

//         return result;
//     }
// }

export function createThrottle<T>(
    cb: (...params: any[]) => Promise<T>,
    timeout: number = 2000
) {
    let inFlight: Promise<T> | null = null;
    let lastResult: T | null = null;
    let timer: ReturnType<typeof setTimeout> | null = null;

    return async function (...params: any[]): Promise<T> {
        // If there's already a cached result, return it immediately
        // Or if there is an in-flight request, just await that
        if (lastResult !== null) {
            return lastResult;
        }
        if (inFlight !== null) {
            return inFlight;
        }

        // No result cached, and no in-flight request → call cb
        inFlight = cb(...params);

        try {
            const result = await inFlight;
            lastResult = result;

            // Clear inFlight now that it’s resolved
            inFlight = null;

            // Start timer to reset the lastResult after the throttle window
            timer = setTimeout(() => {
                lastResult = null;
            }, timeout);

            return lastResult;
        } catch (err) {
            // If the promise rejects, reset everything
            inFlight = null;
            lastResult = null;
            if (timer) {
                clearTimeout(timer);
                timer = null;
            }
            throw err;
        }
    };
}

import { useContext, useState } from "react";

import { analyticsService } from "../../services/analytics-service";
import { AuthContext } from "../../providers/auth-provider/AuthProvider";
import { PinInput } from "../../components/Input/Input";
import { Section } from "../../components/layout/Section";
import { Subtitle1, PageTitle } from "../../brand/typographies";
import { useSuccessError } from "../../hooks/useSuccessError";
import agents from "../../api/agents";
import { StorSelfButton } from "../../components/buttons/StorSelfButton";
import useToast from "../../hooks/useToast";

export const VerifyOtp = () => {
    const { setTenantAuth } = useContext(AuthContext);

    const [submitting, setSubmitting] = useState(false);
    const [code, setCode] = useState("");

    const { toast } = useToast()


    const onSubmit = async (e: any) => {
        e.preventDefault();

        if (code.length < 6) {
            return;
        }
        setSubmitting(true);
        try {
            const phoneNumber = await agents.OTP.verifyOTP(code);

            setSubmitting(false);
            analyticsService.trackEvent("Authenticated Number", { phoneNumber });
            toast.success("You've been authenticated");

            setTenantAuth({ phoneNumber });

            setTimeout(() => {
                // this will update the auth context, which will cause multiple
                // updates, such as fetching the ledgers for the account in the account provider
                // TODO: ? might want to just call the refetch ledgers method directly here

            }, 400);

        } catch (error) {
            analyticsService.trackEvent("Authentication Failed", null);

            if (error instanceof Error) {
                toast.error(error.message || "Unable to validate the otp.");
            } else {
                toast.error("Unable to validate the otp.");
            }
        }
    };

    const resendOtp = async () => {
        setSubmitting(true);
        try {
            await agents.OTP.resendOTP();

            toast.success("Verification token resent.")

            setSubmitting(false);
        } catch (error) {
            if (error instanceof Error) {
                toast.error(error.message || "Oops! Something went wrong.");
            } else {
                toast.error("An unexpected error occurred.");
            }
        }
    };

    return (
        <form onSubmit={onSubmit}>
            <PageTitle>Verify Number</PageTitle>

            <Subtitle1>Please enter the verification code that we sent to your phone via SMS:</Subtitle1>
            <Section sx={{ my: 4 }}>
                <PinInput
                    isInputNum
                    inputmode="numeric"
                    pattern="[0-9]"
                    autocomplete="one-time-code"
                    numInputs={6}
                    pin={code}
                    setPin={setCode} />
            </Section>

            <Subtitle1
                onClick={resendOtp}
                brandColor
                underlined
                center>Resend the verifcation code</Subtitle1>

            <StorSelfButton
                disabled={code.length < 6}
                pending={submitting}
                type='submit'
            >Submit</StorSelfButton>
        </form>
    );
};

import {
    SnackbarAction,
    SnackbarOrigin,
    SnackbarProvider,
    SnackbarProviderProps,
    closeSnackbar,
    enqueueSnackbar,
} from 'notistack'

import styled from "@emotion/styled";
import { H6 } from '../brand/typographies';
import ErrorIcon from '../components/icons/ErrorIcon';
import SuccessIcon from '../components/icons/SuccessIcon';
import InfoIcon from '../components/icons/InfoIcon';
import CancelIcon from '../components/icons/CancelIcon';

type MessageProps = string | React.ReactNode

enum BaseVariant {
    'default',
    'error',
    'success',
    'warning',
    'info',
}

type Variant = keyof typeof BaseVariant

export const iconVarinat = {
    error: <ErrorIcon />,
    success: <SuccessIcon />,
    warning: <InfoIcon />,
    info: <InfoIcon />,
}

const ToastContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-left: 8px;
  .MuiTypography-headline {
    text-transform: capitalize;
  }
`

interface ToastOptions {
    anchorOrigin?: SnackbarOrigin
    autoHideDuration?: number | null
    action?: SnackbarAction
}

export const ToastProvider = ({ children, ...rest }: SnackbarProviderProps) => {
    return (
        <SnackbarProvider iconVariant={iconVarinat} maxSnack={8} {...rest}>
            {children}
        </SnackbarProvider>
    )
}

export const toast = Object.keys(BaseVariant).reduce((obj, variant) => {
    return {
        ...obj,
        [variant]: (message: MessageProps, options?: ToastOptions) => {
            enqueueSnackbar(
                <ToastContent className={`${variant}-toast`}>
                    <H6 white>{variant.charAt(0).toUpperCase() + variant.slice(1)}</H6>
                    {message}
                </ToastContent>,
                {
                    anchorOrigin: { horizontal: 'center', vertical: 'top' },
                    action: (id: any) => (
                        <span style={{ cursor: 'pointer' }} onClick={() => closeSnackbar(id)}>
                            <CancelIcon />
                        </span>
                    ),
                    ...options,
                    variant: variant as Variant,
                }
            )
        },
    }
}, {}) as Record<Variant, any>

const useToast = () => {
    return {
        toast,
        ToastProvider,
        closeAllToast: closeSnackbar,
    }
}

export default useToast

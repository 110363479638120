import React, { useContext, useEffect, useRef, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { Box, Grid, Skeleton } from "@mui/material";

import { Body2, Caption, PageTitle, Subtitle1 } from "../../../brand/typographies";
import { CreditCard } from "../[components]/CreditCard";
import { IPaymentMethod } from "../../../types/PaymentMethod";
import { LedgerEligible, PaymentsContext } from "../../../providers/payments-provider/PaymentsProvider";
import { Section } from "../../../components/layout/Section";
import { StorSelfButton } from "../../../components/buttons/StorSelfButton";
import { useAutopay } from "./useAutopay";
import { useListenToFacilityChange } from "../../../hooks/useListenToFacilityChange";
import { usePaymentNavigation } from "../[hooks]/usePaymentNavigation";
import useAnalytics from "../../../hooks/useAnalytics";

const MascotConfused = React.lazy(() => import('../../../components/icons/MascotConfused'));

const styles = {
    root: {
        padding: '0 36px',
        boxSizing: "border-box",
    },
    slideContainer: {
        width: "100%",
        padding: '0 6px',
    },
    slide: {
        padding: 15,
        minHeight: 100,
        color: '#fff',
    },
    slide1: {
        backgroundColor: '#FEA900',
    },
    slide2: {
        backgroundColor: '#B3DC4A',
    },
    slide3: {
        backgroundColor: '#6AC0FF',
    },
};

function PaymentMethodsSelect({ paymentMethods: a, handleSelect, loading }: { handleSelect: (method: IPaymentMethod) => void, paymentMethods: IPaymentMethod[], loading: boolean }) {
    const [idx, setIdx] = useState(0)

    const [height, setHeight] = useState("35vw");

    console.log({ idx, a, height })
    const paymentMethods = a.length ? [a[0], a[0], a[0], a[0]] : []
    return (
        <Box sx={{ height: { height }, maxHeight: "220px", width: "100%", position: "relative", mt: 2 }}>
            {loading && !paymentMethods.length &&
                <Skeleton height={"35vw"} />
            }
            {
                paymentMethods.length == 1 ?
                    <Box sx={{
                        zIndex: 1,
                        width: "100vw",
                        maxWidth: "380px",
                        height: { height },
                        margin: "0 auto",
                    }}>
                        <CreditCard
                            height={height}
                            setHeight={setHeight}
                            paymentMethod={paymentMethods[0]}
                            key={paymentMethods[0].id}
                            selected={true} />
                    </Box> :
                    <Box sx={{
                        zIndex: 1,
                        position: "absolute",
                        left: 0,
                        marginLeft: "-24px",
                        overflowX: "hidden",
                        width: "100vw",
                        height: { height },
                        maxWidth: "380px",
                        ".react-swipeable-view-container": {
                            backgroundColor: "#fff"
                        },
                        "div": {
                            boxSizing: "border-box",
                        }
                    }}>
                        <SwipeableViews
                            onChangeIndex={(idx) => {
                                setIdx(idx)
                                handleSelect(paymentMethods[idx])
                            }}
                            resistance
                            style={{
                                ...styles.root,
                                height
                            }}
                            slideStyle={
                                {
                                    ...styles.slideContainer,
                                    height,
                                }
                            }
                        >
                            {paymentMethods.map((method, index) =>
                                <CreditCard
                                    setHeight={!index ? setHeight : null}
                                    height={height}
                                    paymentMethod={method}
                                    key={method.id}
                                    selected={index === idx} />)}
                        </SwipeableViews>
                    </Box>
            }
        </Box>
    );
}

const NoBalanceDue = ({ ledgerId }: { ledgerId: string }) => {
    const navigateTo = usePaymentNavigation();
    useAnalytics("NoBalanceDue", true)

    return (
        <>
            <PageTitle>Make a Payment</PageTitle>
            <Section>
                <Subtitle1 center>
                    There is no balance due at this time.
                </Subtitle1>

                <React.Suspense fallback={null}>
                    <MascotConfused />
                </React.Suspense>

                <StorSelfButton
                    analyticsTag="Prepay"
                    onClick={() => navigateTo.paymentAmount({ ledgerId })}
                    variant="outlined">Prepay</StorSelfButton>
            </Section>
        </>
    )
}

export function PaymentMethodPage() {
    const { state: { ledger, loading, ledgerEligible, paymentAmount, fetchingPaymentAmount, fetchingPaymentMethods, paymentMethods }, flowTitle } = useContext(PaymentsContext);
    useListenToFacilityChange(PaymentsContext);
    const navigateTo = usePaymentNavigation();

    const [paymentMethod, setPaymentMethod] = useState<IPaymentMethod | undefined>()

    const { AutopayCheckbox, ordinalDay, selectedDay, autopayEnabled } = useAutopay(ledger)

    useEffect(() => {
        if (paymentMethods.length) {
            setPaymentMethod(paymentMethods[0])
        }
    }, [paymentMethods])

    if (!loading && paymentAmount <= 0) {
        return <NoBalanceDue ledgerId={ledger?.id || ""} />
    }


    if (ledgerEligible === LedgerEligible.Ineligible) {
        return (
            <>
                <PageTitle>{flowTitle}</PageTitle>
                <Subtitle1 center>
                    You are unable to make a payment in your current delinquent state, please contact the facility for more information.
                </Subtitle1>

                <React.Suspense fallback={null}>
                    <MascotConfused />
                </React.Suspense>
            </>
        )
    }

    const onContinue = () => {
        if (paymentMethod?.id) {
            navigateTo.confirmPayment({
                paymentMethodId: paymentMethod.id,
                autoPayDay: autopayEnabled ? (ledger?.invoiceDayOfMonth || selectedDay) : 0
            })
        }
    }

    const onNewPaymentMethod = () => {
        navigateTo.newPaymentMethod()
    }

    if (!fetchingPaymentMethods && !paymentMethods.length) {
        setTimeout(() => {
            navigateTo.newPaymentMethod({ replace: true })
        }, 0)
        return null;
    }

    return (
        <>
            <PageTitle>{flowTitle}</PageTitle>

            <Subtitle1 sx={{ my: 1 }} center>
                Choose a card to make your payments<br />or add a new card.
            </Subtitle1>

            <Grid container alignItems="center" justifyContent="center" spacing={0} mt={2}>
                <Grid item xs></Grid>
                <Grid item xs>
                    <Caption loading={loading} center>
                        Unit
                    </Caption>
                    <Subtitle1 loading={loading} bold center>
                        {ledger?.facilityUnit.unitName}
                    </Subtitle1>
                </Grid>

                <Grid item xs>
                    <Caption loading={loading || fetchingPaymentAmount} center>
                        Total Due
                    </Caption>
                    <Subtitle1 loading={loading || fetchingPaymentAmount} bold center>
                        ${paymentAmount.toFixed(2)}
                    </Subtitle1>
                </Grid>
                <Grid item xs></Grid>

            </Grid>

            <Body2 center error={ledger?.delinquent || ledger?.gateLocked} sx={{ fontStyle: "italic", my: 1 }}>Paid Through: {ledger?.paidThrough}</Body2>

            <PaymentMethodsSelect
                handleSelect={setPaymentMethod}
                paymentMethods={paymentMethods}
                loading={fetchingPaymentMethods}
            />

            <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <AutopayCheckbox />
                {/* <Caption sx={{ opacity: autopayEnabled ? 1 : 0 }}>{`Autopay enabled on the ${ordinalDay} of the month`}</Caption> */}
            </Box>

            <Section sx={{ mt: 5 }}>
                <StorSelfButton
                    analyticsTag="Continue"
                    onClick={onContinue}
                >Continue</StorSelfButton>

                <StorSelfButton
                    analyticsTag="Enter New Card"
                    onClick={onNewPaymentMethod}
                    variant="outlined">Enter New Card</StorSelfButton>
            </Section>
        </>
    );
}

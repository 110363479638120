import gateLocked from "./gateLocked";
import newMoveIn from "./newMoveIn";

export const payGateLocked = async () => {
    gateLocked.ledger.gateLocked = false;
    gateLocked.ledger.facilityUnit.balance = 0;
    gateLocked.ledger.balance = 0;
    gateLocked.ledger.paidThrough = newMoveIn.ledger.paidThrough;
    gateLocked.ledger.nextChargeDate = newMoveIn.ledger.nextChargeDate;
};

import React from 'react'
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import './App.css'
import AppRouter from './router/AppRouter'
import AccountProvider from './providers/account-provider/AccountProvider'
import AuthProvider from './providers/auth-provider/AuthProvider'
import FacilitiesProvider from './providers/facility-provider/FacilityProvider'
import { GoogleScriptProvider } from './providers/GoogleScriptProvider'
import { PopoverContextProvider } from './providers/PopoverContextProvider'
import { companyConfig, kioskFacilityId } from './globalContext';
import { Input } from './components/Input/Input';
import { Box } from '@mui/material';
import { StorSelfButton } from './components/buttons/StorSelfButton';
import { ToastProvider } from './hooks/useToast';

const KioskRouter = React.lazy(() => import('./router/KioskRouter'));
// const PaymentRouter = React.lazy(() => import('./router/PaymentRouter'));

const isStagingBlocked = !!companyConfig?.stagingPassword
  && companyConfig?.stagingPassword !== "preview"
  && companyConfig?.stagingPassword !== localStorage.getItem("stagingPassword")

function App() {
  const [stagingBlocked, setStagingBlocked] = React.useState(isStagingBlocked)
  const [password, setPassword] = React.useState('')
  if (stagingBlocked) {
    const submit = (e: any) => {
      e.preventDefault();
      if (password === companyConfig?.stagingPassword) {
        setStagingBlocked(false)
        localStorage.setItem("stagingPassword", password)
      }
    }
    return (
      <form onSubmit={submit}>
        <Box display="flex" boxSizing="border-box" padding="32px" gap="24px" width="100vw" height="100vh" bgcolor="#FCFCFC" flexDirection="column" justifyContent="center" alignItems="center">
          <Input
            type="password"
            label="Password"
            value={password}
            onChange={(e: any) => { setPassword(e.target.value) }}
            onEnter={submit}
          />

          <Box sx={{ maxWidth: "250px", width: "100%" }}>
            <StorSelfButton type="submit" fullWidth onClick={submit}>Submit</StorSelfButton>
          </Box>
        </Box>
      </form>
    )
  }

  // if (paymentFacilityId) {
  //   return (
  //     <GoogleScriptProvider >
  //       <QueryParamProvider adapter={ReactRouter6Adapter}>
  //         <FacilitiesProvider>
  //           <PopoverContextProvider>
  //             <React.Suspense fallback={<div>Loading...</div>}>
  //               <PaymentRouter />
  //             </React.Suspense>
  //           </PopoverContextProvider>
  //         </FacilitiesProvider>
  //       </QueryParamProvider>
  //     </GoogleScriptProvider >
  //   )
  // }

  if (kioskFacilityId) {
    return (
      <GoogleScriptProvider>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <ToastProvider>
            <PopoverContextProvider>
              <FacilitiesProvider>
                <React.Suspense fallback={<div>Loading...</div>}>
                  <KioskRouter />
                </React.Suspense>
              </FacilitiesProvider>
            </PopoverContextProvider>
          </ToastProvider>
        </QueryParamProvider>
      </GoogleScriptProvider>
    )
  }

  return (
    <GoogleScriptProvider>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <ToastProvider>
          <AuthProvider>
            <AccountProvider>
              <PopoverContextProvider>
                <FacilitiesProvider>
                  <AppRouter />
                </FacilitiesProvider>
              </PopoverContextProvider>
            </AccountProvider>
          </AuthProvider>
        </ToastProvider>
      </QueryParamProvider>
    </GoogleScriptProvider>
  )
}

export default App
